import React, { useMemo } from 'react';
import styled from 'styled-components';
import AvatarPlaceholder from './AvatarPlaceholder';

import Image from './Image';

type PlaceholderProps = {
  width?: string | number | null,
  circle?: boolean,
  radius?: string
}
const Placeholder = styled.div<PlaceholderProps>`
  background-color: #e3e3e3;
  width: ${props => props.width || `100%`};
  height: ${props => props.width || `100%`};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 20px;
  ${props => props.circle && !props.radius && `
        border-radius: 50%;
    `}
  ${props => props.radius && `
        border-radius: ${props.radius};
    `}
`;
Placeholder.defaultProps = {
  width: '50px',
  circle: true
};

type AvatarProps = {
  src: string | null
  placeholder?: string | null
  circle?: boolean
  width?: string | number | null
  css?: string | null
  asBackground?: boolean
}
const Avatar = ({src, placeholder, circle, css, asBackground, ...props}: AvatarProps) => {
  if (src) {
    const defaultCss = 'background-color: #e3e3e3;'
    const _css = css ? `${defaultCss}${css}` : defaultCss;
    return (
      <Image src={src} circle={circle} css={_css} asBackground={asBackground} {...props} />
    )
  } else if (placeholder) {
    return <Placeholder {...props}>{placeholder}</Placeholder>
  } else {
    return <AvatarPlaceholder {...props} />
  }
}

Avatar.defaultProps = {
  circle: true,
  asBackground: false
}

export default Avatar;
