import { createGlobalStyle } from "styled-components";

export const colors = {
  content: "#272727",
  contentGrey: "grey",
  bodyBackground: "#f8f8f8",
  newlightGrey:"#C7C7C7",
  lightGrey: "#efefef",
  lighterGrey: `#bbbbbb`,
  inputBorder: "#CCCCCC",
  primary: "#EC304A",
  primaryHover: "#EC304AD8",
  warning: "#ffab31",
  green: "#02b540",
  greenHover: "#038f34",
  yello: "#fdc518",
  yelloHover: "#c99c14",
  border: "#eaeaea",
  lightPink: "#fff8ff",
  alertYellow: `#fdfcf4`,
  alertYellowDark: `#febf44`,
};

export const fontSizes = {
  general: "14px",
  pageTitle: "24px",
  cardTitle: "19px",
};

const AppStyle = createGlobalStyle`
  body {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.5;

    button {
      border: 0;
      box-shadow: none;
      outline: 0;

      &:hover, &:active, &:focus {
        box-shadow: none;
        outline: 0;
      }
    }
  }

  :root {
    --title-font: 'Lexend Deca', sans-serif;

    --text-disabled: rgba(0, 0, 0, 0.25);
    --color-body-background: ${colors.bodyBackground};
    --light-grey: ${colors.lightGrey};
    --input-border: ${colors.inputBorder};
    --color-primary: ${(props) => props.theme.colors.primary};
    --color-primary-hover: ${(props) => props.theme.colors.primaryHover};
    --color-warning: ${colors.warning};
    --color-content: ${colors.content};
    --color-content-grey: ${colors.contentGrey};
    --color-green: ${colors.green};
    --color-green-hover: ${colors.greenHover};
    --color-yellow: ${colors.yello};
    --color-yellow-hover: ${colors.yelloHover};
    --color-border: ${colors.border};
    --color-grey-lighter: ${colors.lighterGrey};
    --color-pink-light: ${colors.lightPink};
    --color-alert-yellow: ${colors.alertYellow};
    --color-alert-yellow-dark: ${colors.alertYellowDark};
  }

  .ant-message {
    z-index: 9999;
  }
  .ant-tour {
    max-width: 100% !important;
    width: 320px !important;
  }
  .ant-tour-content {
    .ant-tour-inner {
      border-radius: 7px !important;
    }
    .ant-tour-close {
      display: none !important;
    }
    .ant-tour-header {
      padding: 20px 20px 5px !important;
    }
    .ant-tour-description {
      padding: 5px 20px 10px !important;
    }
    .ant-tour-footer {
      padding: 5px 20px 20px !important;
      .ant-tour-buttons {
        .ant-btn {
          height: 30px !important;
          padding: 4px 7px !important;
        }
      }
    }
  }
`;

export const WidgetStyle = createGlobalStyle`
  body {
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    background-color: transparent;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  :root {
    --title-font: 'Lexend', sans-serif;

    --text-disabled: rgba(0, 0, 0, 0.25);
    --color-body-background: ${colors.bodyBackground};
    --light-grey: ${colors.lightGrey};
    --input-border: ${colors.inputBorder};
    --color-primary: ${colors.primary};
    --color-primary-hover: ${colors.primaryHover};
    --color-warning: ${colors.warning};
    --color-content: ${colors.content};
    --color-content-grey: ${colors.contentGrey};
    --color-green: ${colors.green};
    --color-green-hover: ${colors.greenHover};
    --color-yellow: ${colors.yello};
    --color-yellow-hover: ${colors.yelloHover};
    --color-border: ${colors.border};
    --color-grey-lighter: ${colors.lighterGrey};
    --color-pink-light: ${colors.lightPink};
  }
`;

export default AppStyle;
