import styled from "styled-components";

interface CssProps {
  css?: string;
}

interface CardDescriptionProps extends CssProps {
  grey?: boolean;
  ignoreLineBreak?: boolean;
}
export const CardDescription = styled.div<CardDescriptionProps>`
  ${(props) =>
    !props.ignoreLineBreak &&
    `
      white-space: pre-line;
   `}
  ${(props) => props.grey && `color: var(--color-content-grey);`}
   ${(props) => props.css}
`;
CardDescription.defaultProps = {
  ignoreLineBreak: false,
};
export const CardHeader = styled.div<CssProps>`
  padding: 20px 25px;
  border-bottom: 1px solid var(--light-grey);
  ${(props) => props.css}
`;

export const CardFooter = styled.div<CssProps>`
  padding: 20px 25px;
  ${(props) => props.css}
`;

export const CardImage = styled.div<{
  image: string;
  simple?: boolean;
  aspectRatio?: number;
  css?: string;
}>`
  min-height: 100px;
  background-color: #dddddd;
  ${(props) => props.aspectRatio && `padding-top: ${100 / props.aspectRatio}%;`}
  ${(props) =>
    !props.simple &&
    `
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
   `}
   ${(props) =>
    props.image &&
    `
      background-image: url("${props.image}");
      background-size: cover;
      background-position: center;
   `}
   ${props => props.css}
`;

interface CardProps extends CssProps {
  simple?: boolean;
  shadow?: boolean;
}
const Card = styled.div<CardProps>`
  ${(props) =>
    !props.simple &&
    `
      padding-bottom: 10px;
   `}
  background-color: white;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.shadow ? `#eeeeee` : `var(--color-border)`)};
  ${(props) =>
    props.shadow &&
    `
      box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
   `}
  ${(props) => props.css}
`;

export default Card;

export const CardTitle = styled.div<{ css?: string, large?: boolean }>`
  font-family: var(--title-font);
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: ${props => props.large ? '18px' : '16px'};
  ${(props) => props.css}
`;

export const CardGreyInner = styled.div<{css?: string; simple?: boolean}>`
  border-radius: 4px;
  border: 1px solid var(--color-border);
  background-color: var(--color-body-background);
  ${props => !props.simple ? 'padding: 14px 15px;' : ''}
  ${(props) => props.css}
`;
