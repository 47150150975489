import React from 'react';
import styled from 'styled-components';

import { Menu as AntMenu } from 'antd';

export const MenuItem = styled(AntMenu.Item)``;

const Menu = styled(({ tab, gutter, ...props }) => <AntMenu {...props}/>)`
  ${({ tab, gutter }) => tab && `
      border-bottom: none;
      background: transparent;
      line-height: inherit;
      &.ant-menu-horizontal > ${MenuItem} {
        letter-spacing: 1px;
        padding-inline: ${gutter / 2}px;
        border-bottom: none;
        &:hover, &:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), &.ant-menu-item-selected, &:focus {
          border-bottom: none;
          color: var(--color-primary);
        }
        &:after {
          inset-inline: ${gutter / 2}px;
        }
      }
      &.ant-menu-horizontal > .ant-menu-overflowed-submenu {
        padding-inline: 0 ${gutter / 2}px;
        .ant-menu-submenu-title {
          bottom: 4px;
          letter-spacing: 1px;
          font-weight: 500;
        }
        &:after {
          inset-inline: ${gutter / 2}px;
        }
      }
      > ${MenuItem}:first-child {
        padding-inline: 0 ${gutter / 2}px;
        &:after {
          inset-inline: 0 ${gutter / 2}px;
        }
      }
    `}
`;
Menu.defaultProps = {
  gutter: 30
}
export default Menu;
