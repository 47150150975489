import styled from 'styled-components';

interface Props {
     inline?: boolean
     color?: string
     size?: 'large' | 'medium' | 'small'
     css?: string
     medium?: boolean
}

const fontSize = {
     small: 14,
     medium: 15,
     large: 20
}

const Amount = styled.div<Props>`
  font-size: ${props => fontSize[props.size]}px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  ${props => props.inline && `
        display: inline;
   `}
  ${props => props.color && `
        color: var(--color-${props.color});
   `}
  ${props => props.css}
`;

Amount.defaultProps = {
     inline: false,
     size: 'large'
}

export default Amount;
