import styled from 'styled-components';

const CardInner = styled.div<{css?: string, thin?: boolean}>`
   padding: ${props => props.thin ? '10px 15px' : '20px 25px'};
   @media all and (max-width: 768px) {
      padding: ${props => props.thin ? '10px 12px' : '15px 15px'};
   }
   border-bottom: 1px solid var(--light-grey);
   :last-child {
      border-bottom: none;
   }
   ${props => props.css}
`;

export default CardInner;
